import React from "react";
import logo from "./assets/img/kusha.in_logo.png";
import "./custom.scss";

function App() {
  return (
    <div className="App">
      <h1 className="sr-only">
        Kusha.in<br />
      </h1>
      <header  className="app-header">
        <img className="img-fluid img-logo" src={logo} alt="logo" />
      </header>
      <small className="heading-desc">One stop for all you online needs. <br />
      Coming Soon</small>
    </div>
  );
}

export default App;
